import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Link as ScrollLink } from 'react-scroll';

import AppleTouch from '../assets/images/favicons/apple-touch-icon.png';
import Fevicon32 from '../assets/images/favicons/favicon-32x32.png';
import Fevicon16 from '../assets/images/favicons/favicon-16x16.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/apton-icons.css';
import '../assets/css/animate.min.css';
import 'swiper/swiper-bundle.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import 'react-modal-video/css/modal-video.min.css';

const Layout = (props) => {
  const [scrollTop, setScrollTop] = useState(false);

  const handleScrollTop = () => {
    if (window.scrollY > 70) {
      setScrollTop(true);
    } else if (window.scrollY < 70) {
      setScrollTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollTop);
    return () => {
      window.removeEventListener('scroll', handleScrollTop);
    };
  }, [scrollTop]);
  return (
    <div>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>{props.pageTitle}</title>
        <link rel='apple-touch-icon' sizes='180x180' href={AppleTouch} />
        <link rel='icon' type='image/png' sizes='32x32' href={Fevicon32} />
        <link rel='icon' type='image/png' sizes='16x16' href={Fevicon16} />
        <link
          href='https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap'
          rel='stylesheet'
        />
      </Helmet>

      <div className='page-wrapper' id='wrapper'>
        {props.children}
      </div>

      {scrollTop === true ? (
        <ScrollLink
          to='wrapper'
          smooth={true}
          duration={500}
          className='scroll-to-top'
        >
          <i className='fa fa-angle-up'></i>
        </ScrollLink>
      ) : null}
    </div>
  );
};

export default Layout;
