import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import FooterLogo from '../assets/images/blueWithTagline.png';

const Footer = () => {
  return (
    <div>
      <footer className='site-footer'>
        <div className='site-footer__upper'>
          <div className='container'>
            <div className='row  justify-content-between'>
              <div className='col-lg-4'>
                <div className='footer-widget footer-widget__about'>
                  <a href='index.html'>
                    <img src={FooterLogo} width='200' alt='awesome post' />
                  </a>
                  <p>
                    We believe in traditions and incorporate them within our
                    innovations
                  </p>
                </div>
                <div className='footer-widget'>
                  <div className='footer-widget__social'>
                    <a href='#none'>
                      <i className='fab fa-facebook-square'></i>
                    </a>
                    <a
                      href='https://www.linkedin.com/company/insurge-technologies/about/'
                      target='_blank'
                    >
                      <i className='fab fa-linkedin'></i>
                    </a>
                    <a href='#none'>
                      <i className='fab fa-instagram'></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className='col-lg-4'>
                <div className='footer-widget__links-wrap'>
                  <div className='footer-widget'>
                    <h3 className='footer-widget__title'>Important Links</h3>
                    <ul className='list-unstyled  '>
                      <li className='mr-4 ' style={{ cursor: 'pointer' }}>
                        <ScrollLink
                          activeClass='current'
                          to='home'
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          Home
                        </ScrollLink>
                      </li>
                      <li className='mr-4 ' style={{ cursor: 'pointer' }}>
                        <ScrollLink
                          activeClass='current'
                          to='about'
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          About Us
                        </ScrollLink>
                      </li>
                      <li className='mr-4 ' style={{ cursor: 'pointer' }}>
                        <ScrollLink
                          activeClass='current'
                          to='services'
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          Services
                        </ScrollLink>
                      </li>
                      <li className='mr-4 ' style={{ cursor: 'pointer' }}>
                        <ScrollLink
                          activeClass='current'
                          to='features'
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          Features
                        </ScrollLink>
                      </li>
                      <li className='mr-4 ' style={{ cursor: 'pointer' }}>
                        <ScrollLink
                          activeClass='current'
                          to='faq'
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          FAQ
                        </ScrollLink>
                      </li>
                      <li className='mr-4 ' style={{ cursor: 'pointer' }}>
                        <ScrollLink
                          activeClass='current'
                          to='contact'
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          Contact Us
                        </ScrollLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='site-footer__bottom'>
          <div className='container text-center'>
            <p>Copyright © 2024. All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
