import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Link as ScrollLink } from 'react-scroll';

const NavLinks = () => {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const handleDropdownStatus = () => {
    setDropdownStatus(!dropdownStatus);
  };
  return (
    <ul className='main-nav__navigation-box '>
      {/* <li className='py-3'>
        <Link to='/'>Home</Link>
      </li> */}
      <li className='py-3'>
        <ScrollLink
          activeClass='current'
          to='home'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Home
        </ScrollLink>
      </li>
      <li className='py-3'>
        <ScrollLink
          activeClass='current'
          to='about'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          About Us
        </ScrollLink>
      </li>
      <li className='py-3'>
        <ScrollLink
          activeClass='current'
          to='services'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Services
        </ScrollLink>
      </li>
      <li className='py-3'>
        <ScrollLink
          activeClass='current'
          to='features'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Features
        </ScrollLink>
      </li>
      <li className='py-3'>
        <ScrollLink
          activeClass='current'
          to='faq'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          FAQ
        </ScrollLink>
      </li>
      <li className='py-3'>
        <ScrollLink
          activeClass='current'
          to='contact'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Contact Us
        </ScrollLink>
      </li>
    </ul>
  );
};

export default NavLinks;
