import React, { useState, useEffect } from 'react';
import NavLinks from './NavLinks';

import LogoImage from '../assets/images/blueWithTagline.png';

const HeaderHome = (props) => {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sticky]);

  return (
    <header
      className={`site-header-one stricky  ${props.extraClassName} ${
        sticky === true ? 'stricky-fixed stricked-menu' : ' '
      }`}
    >
      <div className='container d-flex align-items-center '>
        <div className='site-header-one__logo w-full'>
          {sticky === true ? (
            <>
              {' '}
              <a href='/'>
                <img
                  className='mb-2'
                  src={LogoImage}
                  width='200'
                  alt='awesome post'
                />
              </a>
            </>
          ) : (
            <></>
          )}

          <span className='side-menu__toggler '>
            {sticky === true ? (
              <i className='fa fa-bars '></i>
            ) : (
              <i className='fa fa-bars text-white mt-3 ml-n4'></i>
            )}
          </span>
        </div>
        <div
          className='main-nav__main-navigation main-nav__right'
          style={sticky === false ? { margin: 'auto' } : {}}
        >
          <NavLinks />
        </div>
        {/* <div className='main-nav__right'>
          <a href='#none' className={`thm-btn ${props.btnClass}`}>
            <span>Book a Demo</span>
          </a>
        </div> */}
      </div>
    </header>
  );
};

export default HeaderHome;
